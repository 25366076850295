import React, { Component } from 'react'
import styles from './index.module.scss'
import Img from 'Components/UI/Img'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import { getBagItems } from 'Components/Utils/helpers'
import Price from 'Components/Utils/FormatPrice'
import SummaryInformation from 'Components/UI/SummaryInformation'
import PromoCodeAccordion from 'Components/UI/PromoCodeAccordion'
import { StaticQuery, graphql } from 'gatsby'
require('@gouch/to-title-case')

class OrderOverviewPanel extends Component {
  constructor(props) {
    super(props)
    this.promoForm = React.createRef()
    this.state = {
      promoIsLoading: false,
      promo: undefined,
      promoIsApplied: false,
      items: getBagItems()
    }
  }
  componentDidMount() {
    if (AppStore.promoCodes && AppStore.promoCodes.length > 0) {
      this.setState({ promoIsApplied: true, promoIsLoading: false, promo: AppStore.promoCodes[0] })
    }
    AppStore.on(AppConstants.APPLY_PROMO_CODE, this.didPromoCodeApply)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.APPLY_PROMO_CODE, this.didPromoCodeApply)
  }
  didPromoCodeApply = (data) => {
    this.promoForm.current.setValueExplicit('')
    if (data.validation && data.validation.is_valid) this.setState({ promoIsApplied: true, promoIsLoading: false, promo: data })
    else this.setState({ promoIsLoading: false })
  }
  onFormSubmit = () => {
    this.setState({ promoIsLoading: true })
    const code = this.promoForm.current.state.value
    const totalAmount = AppStore.bagItemsTotalAmount()
    const customer = AppStore.getCurrentCustomer()
    setTimeout(AppActions.applyPromoCode, 0, { total_amount: totalAmount.totalWithSale, code, email: customer.email })
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            dataJson {
              PRIVATE_SALE
            }
          }
        `}
        render={data => {
          const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
          const isPrivateSale = data.dataJson.PRIVATE_SALE
          const lockSale = !isCustomer && isPrivateSale
          const isOneItem = this.state.items.length === 1 ? true : false
          const products = this.state.items.map((item, index) => {
            const {product, size} = item
            const src = product.media.rect[0].srcSmall
            const showSale = product.sale.inSale && !lockSale
            const price = Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, product.price )
            return (
              <div className={`${styles.productBlock} u-col-12 u-mrg-t--40 u-mrg-b--40`} key={`product-overview-bag---${product.id}-${index}`}>
                <div className={`u-row`}>
                  <Img
                    baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
                    className={`${styles.productImg} u-col-2 u-w-perc--100 u-h-perc--100`}
                    src={src}
                    srcRetina={src}
                  />
                  <div className={`u-pad-l--20`}>
                    <div className={`t-title-1 u-uppercase`}>{product.title}</div>
                    <div className='u-height--10' />
                    <div className={`t-paragraph-0 t-color-gray`}>StyleID: {product.style_id} – SKU: {product.sku}</div>
                    <div className={`t-paragraph-0 t-color-gray`}>Color: {product.color.name.toTitleCase()} / Size: {size.name}</div>
                  </div>
                  <div className={`t-paragraph-0 t-color-black u-absolute u-pos-b--0 u-pos-r--0`}>
                    { !showSale && <span>{price}</span>}
                    { showSale &&
                      <span>
                        <del>
                          <span>{price}</span>
                        </del>
                        <span className={styles.salePrice}>{Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, product.sale.price )}</span>
                      </span>
                    }
                  </div>
                </div>
              </div>
            )
          })
          return (
            <div className={`t-bg-mediumgray`}>
              <div className={`${styles.parentInside}`}>
                <div className={`u-text-center`}>
                  <svg className={`${styles.bagIcon} u-inline-block`}>
                    <use xlinkHref='#bag-icon' />
                  </svg>
                  <p className={`t-color-black t-paragraph-1 u-inline-block u-mrg-l--20`}>{`${this.state.items.length} ${isOneItem ? `Item` : `Items`}`}</p>
                </div>
                <div className='u-height--20' />
                <div className='u-separator t-bg-gray'></div>
                {products}
                <div className='u-separator t-bg-gray'></div>
                {!this.state.promoIsApplied &&
                <PromoCodeAccordion
                  ref={this.promoForm}
                  isLoading={this.state.promoIsLoading}
                  onFormSubmit={this.onFormSubmit}
                />
                }
                {this.state.promoIsApplied && <div className='u-height--20' />}
                <div className={`u-height--only-lg--10`} />
                <div className={`t-bg-mediumgray`}>
                  <SummaryInformation promo={this.state.promo} lockSale={lockSale} />
                  <div className='u-height--20' />
                  <div className='u-height--40 u-show-in-mobile' />
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default OrderOverviewPanel
