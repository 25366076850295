import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getCountriesShippingAllowed } from 'Components/UI/Misc/countries'
import find from 'lodash/find'
import Form from 'Components/UI/Form'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import styles from './index.module.scss'

const countries = getCountriesShippingAllowed()

class AddressForm extends Component {
  state = {
    form: []
  }
  constructor(props) {
    super(props)
    this.form = React.createRef()
  }
  setupForm = () => {
    let currentCountry = this.props.data.country === '' ? { value: 'FR', label: 'France' } : countries.filter(country => country.value === this.props.data.country)
    currentCountry = WindowStore.getViewType() === AppConstants.DESKTOP ? currentCountry : currentCountry[0]
    const form = [
      [
        {
          type: 'input',
          props: {
            id: this.props.type.toLowerCase() + '@' + 'first_name',
            className: 'u-col-lg-5',
            type: 'text',
            placeholder: 'First Name',
            value: this.props.data.first_name,
            required: true
          }
        },
        {
          type: 'input',
          props: {
            id: this.props.type.toLowerCase() + '@' + 'last_name',
            className: ' u-offset-lg-1 u-col-lg-6',
            type: 'text',
            placeholder: 'Last Name',
            value: this.props.data.last_name,
            required: true
          }
        }
      ],
      {
        type: 'input',
        props: {
          id: this.props.type.toLowerCase() + '@' + 'company',
          className: 'u-col-12',
          type: 'text',
          placeholder: 'Company Name',
          value: this.props.data.company,
          required: false
        }
      },
      {
        type: 'input',
        props: {
          id: this.props.type.toLowerCase() + '@' + 'address_1',
          className: 'u-col-12',
          type: 'text',
          placeholder: 'Address',
          value: this.props.data.address_1,
          required: true
        }
      },
      {
        type: 'textarea',
        props: {
          id: this.props.type.toLowerCase() + '@' + 'address_2',
          className: 'u-col-12 u-mrg-b--10',
          type: 'text',
          placeholder: 'Additional address information',
          value: this.props.data.address_2,
          required: false
        }
      },
      [
        {
          type: 'select',
          props: {
            id: this.props.type.toLowerCase() + '@' + 'country',
            className: 'u-col-lg-6 u-mrg-t--20',
            options: countries,
            isSearchable: true,
            placeholder: 'Select a country',
            value: currentCountry,
            required: true
          }
        },
        {
          type: 'input',
          props: {
            id: this.props.type.toLowerCase() + '@' + 'city',
            className: 'u-col-lg-5 u-offset-lg-1',
            type: 'text',
            placeholder: 'City',
            value: this.props.data.city,
            required: true
          }
        },
      ],
      {
        type: 'input',
        props: {
          id: this.props.type.toLowerCase() + '@' + 'state',
          className: 'u-col-12',
          type: 'text',
          placeholder: 'State / Province',
          value: this.props.data.state,
          required: false
        }
      },
      {
        type: 'input',
        props: {
          id: this.props.type.toLowerCase() + '@' + 'postcode',
          className: 'u-col-12',
          type: 'text',
          placeholder: 'Postcode',
          value: this.props.data.postcode,
          required: true
        }
      }
    ]
    if (this.props.type === AppConstants.SHIPPING) {
      form.push(
        // {
        //   type: 'input',
        //   props: {
        //     id: 'mail',
        //     className: 'u-col-12',
        //     type: 'email',
        //     placeholder: 'Enter your mail',
        //     value: this.props.data.email,
        //     required: true
        //   }
        // },
        {
          type: 'input',
          props: {
            id: this.props.type.toLowerCase() + '@' + 'phone',
            className: 'u-col-12',
            type: 'tel',
            placeholder: 'Telephone (ex. +XX X XX XX XX XX)',
            value: this.props.data.phone,
            required: true
          }
        }
      )
    }
    this.setState({ form })
  }
  componentDidMount() {
    this.setupForm()
  }
  render() {
    let country = find(countries, { value: this.props.data.country } )
    country = country === undefined ? '' : `, ${country.label}`
    const infos = () => (
      <div className="t-paragraph-1">
        {this.props.data.company.length > 0 && <p>{this.props.data.company}</p> }
        <p>{[this.props.data.first_name, this.props.data.last_name].join(' ')}</p>
        {this.props.data.address_1.length > 0 && <p>{this.props.data.address_1}</p> }
        {this.props.data.address_2.length > 0 && <p>{this.props.data.address_2}</p> }
        {this.props.data.postcode.length > 0 && <p>{this.props.data.postcode} {this.props.data.city}{country}</p>}
      </div>
    )
    return (
      <div className={styles.parent}>
        { this.props.isEditMode ?
        <Form items={this.state.form} submit={this.props.submit} onFail={() => { if (this.props.onFail) this.props.onFail() }} ref={this.form}>
          { this.props.children }
        </Form> :
        infos()
        }
      </div>
    )
  }
}

AddressForm.propTypes = {
  isEditMode: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

AddressForm.defaultProps = {
  data: {
    country: 'FR',
    company: '',
    first_name: '',
    last_name: '',
    address_1: '',
    address_2: '',
    postcode: '',
    city: ''
  }
}

export default AddressForm