import React, { PureComponent } from 'react'
import styles from './index.module.scss'
import Accordion from 'Components/UI/Accordion'
import AppConstants from 'AppConstants'
import Link from 'Components/Utils/Link'
import Price from 'Components/Utils/FormatPrice'

class ComplimentaryShipping extends PureComponent {
  constructor(props) {
    super(props)
  }
  render() {
    const acceptedCards = [ 'paypal', 'visa', 'mastercard', 'american-express'].map(card => {
      return (
        <li key={`type-${card}`} className={`${styles.cardIcon} u-inline-block u-mrg-r--10`}>
          <img className={`u-fit`} alt={`${card}`} src={`${AppConstants.S3_URL}icons/cards/${card}.svg`}/>
        </li>
      )
    })
    return (
      <div className={`t-bg-mediumgray`}>
        <div className={`${styles.parentInside}`}>
          <div className={`t-bg-mediumgray`}>
            <Accordion panelClassName={`t-color-black t-paragraph-0`} title={`May we help?`} open={false}>
              {/* <a href="tel:+33145081412">Tel: +33 (0) 1 45 08 14 12</a> */}
              <p>To reach our online Client Assistants by email, use our <Link className={`u-simple-hyperlink`} to={`/contact`}>contact form</Link> or by send us directly to <a className={`u-simple-hyperlink`} href={`mailto:info@stellaluna.paris`}>info@stellaluna.paris</a></p>
              <div className={`u-height--lg--20`} />
              <div className={`u-height--only-lg--20`} />
            </Accordion>
            <Accordion panelClassName={`t-color-black t-paragraph-0`} title={`Payment Options`} open={false}>
              <ul>{acceptedCards}</ul>
              <div className='u-height--10' />
              <h3 className={`t-color-black t-paragraph-0 u-col-6`}>Secured Payments</h3>
              <Link target={`_blank`} to={`https://stripe.com`}><img className={`${styles.stripeLogo} u-mrg-t--10`} src={`${AppConstants.S3_URL}icons/powered_by_stripe.svg`}/></Link>
              <div className={`u-height--lg--20`} />
              <div className={`u-height--only-lg--20`} />
            </Accordion>
            <Accordion panelClassName={`t-color-black t-paragraph-0`} title={`Returns`} open={false}>
              <p>For online purchases, Stella Luna will refund the purchase price of merchandise that is returned in its original condition, with unscratched soles, and accompanied by the original invoice, original Stella Luna packaging and security return/exchange label intact and attached to the item. Customized products cannot be returned. <br/><br/>You must inform Stella Luna of your intention to return the merchandise within 14 days of the date of delivery by directly returning the merchandise to Stella Luna: <br/><br/><span className={`t-bold`}>MPO Futurlog / Stella Luna<br/>6228 Boulevard de l'Europe,<br/>53700 Villaines-la-Juhel</span><br/><br/>Shoes must be returned with the original invoice as well as a written and signed explanation of the reason for the return.</p>
              <div className={`u-height--lg--20`} />
              <div className={`u-height--only-lg--20`} />
            </Accordion>
            <Accordion panelClassName={`t-color-black t-paragraph-0`} title={`Shipping Options`} open={false}>
              <p>{`${Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, 0 )} Free shipping`}</p>
              <div className={`u-height--lg--20`} />
              <div className={`u-height--only-lg--20`} />
            </Accordion>
          </div>
        </div>
      </div>
    )
  }
}

export default ComplimentaryShipping
