import React, { Component } from 'react'
import { CardElement } from 'react-stripe-elements'
import { ERROR } from 'Components/UI/Notifications'
import Form from 'Components/UI/Form'
import styles from './index.module.scss'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'

const createOptions = (type=AppConstants.DESKTOP) => {
  return {
    style: {
      base: {
        color: '#9B9B9B',
        fontSize: type === AppConstants.MOBILE ? '16px' : '26px',
        lineHeight: type === AppConstants.MOBILE ? '16px' : '26px',
        letterSpacing: '0.9px',
        fontFamily: `'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif`,
        '::placeholder': {
          color: '#9B9B9B',
        }
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }
}

class CardForm extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.cardElement = React.createRef()
    this.state = {
      viewType: undefined
    }
  }
  handleSubmit = () => {
    if (this.props.stripe) {
      const customer = AppStore.getCurrentCustomer()
      this.props.stripe
        .createPaymentMethod('card', {
          billing_details: {
            name: `${customer.billing.first_name} ${customer.billing.last_name}`,
            email: `${customer.email}`,
            phone: `${customer.billing.phone}`
          }
        })
        .then((payload) => {
          if (payload.error) {
            setTimeout(AppActions.openNotification, 0, { type: ERROR, message: payload.error.message })
          } else {
            setTimeout(AppActions.setStripeTokenToCustomer, 0, payload.paymentMethod.id )
          }
        })
    } else {
      setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Something went wrong, please try again' })
    }
  }
  onTryForPayment = () => {
    setTimeout(AppActions.pay, 0)
  }
  onHandleCardAction = (data) => {
    const currentOrder = data.order
    if (this.props.stripe) {
      const intentSecret = data.client
      this.props.stripe
      .handleCardAction(intentSecret)
      .then((result) => {
        if (result.error) {
          setTimeout(AppActions.openNotification, 0, { type: ERROR, message: result.error.message })
        } else {
          setTimeout(AppActions.setStripePaymentIntent, 0, { intent: result.paymentIntent.id, order: currentOrder } )
        }
      })
    } else {
      setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Something went wrong, please try again' })
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    AppStore.on(AppConstants.SET_STRIPE_TOKEN_TO_CUSTOMER, this.onTryForPayment)
    AppStore.on(AppConstants.SET_STRIPE_PAYMENT_INTENT, this.onTryForPayment)
    AppStore.on(AppConstants.STRIPE_HANDLE_CARD_ACTION, this.onHandleCardAction)
    this.resize()
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.SET_STRIPE_TOKEN_TO_CUSTOMER, this.onTryForPayment)
    AppStore.off(AppConstants.SET_STRIPE_PAYMENT_INTENT, this.onTryForPayment)
    AppStore.off(AppConstants.STRIPE_HANDLE_CARD_ACTION, this.onHandleCardAction)
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const viewType = WindowStore.getViewType()
    if(viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
  }
  render() {
    return (
      <div className={``}>
        <Form className={`u-mrg-t--20`} submit={this.handleSubmit} onFail={() => {}} ref={this.form}>
          <label>
            <div className={`u-mrg-t--40`} />
            <div className={`t-title-1 u-uppercase`}>Credit Card</div>
            <div className={`u-mrg-t--20`} />
            <CardElement
              ref={this.cardElement}
              className={`${styles.input} t-paragraph-1 u-fit-w u-block u-pad-b--20 form-control`}
              {...createOptions(this.state.viewType)}
            />
          </label>
          <p className={`t-color-gray t-paragraph-0 u-pad-t--10`}>By completing your order, you accept Stella Luna's <a href={`/terms-and-conditions`} target={`_blank`} className={`u-simple-hyperlink`}>Terms and Conditions</a>.</p>
          <BackgroundBtn
            className='u-col-lg-8 u-mrg-t--50'
            text='Confirm and Place Order'
            type='submit'
            highlighted
            fullBlack
          />
        </Form>
      </div>
    )
  }
}

CardForm.defaultProps = {
}

export default CardForm
