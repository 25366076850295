import React, { Component } from 'react'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Layout from 'Components/Layout'
import CheckingOutAs from 'Components/Checkout/CheckingOutAs'
import OrderOverviewPanel from 'Components/UI/OrderOverviewPanel'
import ComplimentaryShipping from 'Components/UI/ComplimentaryShipping'
import CheckoutBillingShippingForm from 'Components/UI/CheckoutBillingShippingForm'
import Analytics from 'Components/Utils/Analytics'
import { getBagItems, formatProductForGA } from 'Components/Utils/helpers'
import styles from './index.module.scss'
import { navigate } from 'gatsby'

class Billing extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    if (typeof window !== `undefined`) this.checkRedirection()
    this.state = {
      items: getBagItems(),
      action: AppConstants.SHIPPING,
      shippingIsDone: false,
      billingIsDone: false,
      paymentIsDone: false
    }
  }
  areShoesValid = () => {
    const items = getBagItems()
    if (!items || items.length === 0) return false
    for (let index = 0; index < items.length; index++) {
      const element = items[index]
      const {size} = element
      if (!size) return false
    }
    return true
  }
  checkRedirection = () => {
    const customer = AppStore.getCurrentCustomer()
    if (!customer || !customer.email) navigate(`/checkout`)
    if (!this.areShoesValid()) navigate(`/bag`)
  }
  componentDidMount() {
    AppStore.on(AppConstants.SET_CUSTOMER, this.onSetCustomer)
    AppStore.on(AppConstants.PAY, this.onPaymentDone)
    this.checkRedirection()
    Analytics.gEcom('begin_checkout', { value: parseInt(AppStore.bagItemsTotalAmount().totalWithSale), currency: AppConstants.CURRENCY, items: this.state.items.map(item => formatProductForGA(item.product, item.size)), coupon: 'unset' })
    setTimeout(() => { this.setState({ action: AppConstants.SHIPPING }) }, 0)
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.SET_CUSTOMER, this.onSetCustomer)
    AppStore.off(AppConstants.PAY, this.onPaymentDone)
  }
  onSetCustomer = () => {
    const customer = AppStore.getCurrentCustomer()
    if (customer && customer.shipping && this.state.action === AppConstants.SHIPPING && this.state.shippingIsDone) {
      this.setState({ action: AppConstants.BILLING })
    } else if (customer && customer.shipping && customer.billing && this.state.action === AppConstants.BILLING && this.state.billingIsDone) {
      this.setState({ action: AppConstants.PAY })
    }
  }
  getFormData = (customer, form, type) => {
    return {
      first_name: form[`${type}@first_name`].state.value,
      last_name: form[`${type}@last_name`].state.value,
      address_1: form[`${type}@address_1`].state.value,
      address_2: form[`${type}@address_2`].state.value,
      company: form[`${type}@company`].state.value,
      state: form[`${type}@state`].state.value,
      country: form[`${type}@country`].getValue().value,
      city: form[`${type}@city`].state.value,
      postcode: form[`${type}@postcode`].state.value,
      phone: (form[`${type}@phone`] && form[`${type}@phone`].state) ? form[`${type}@phone`].state.value : customer.shipping.phone
    }
  }
  onPaymentDone = () => {
    const customer = AppStore.getCurrentCustomer()
    if (customer.type === AppConstants.CUSTOMER) setTimeout(AppActions.reloadUserFromServer, 0, customer.id)
    else setTimeout(AppActions.resetCustomer, 0, { type: AppConstants.GUEST_CUSTOMER })
    Analytics.gEcom('checkout_progress', { value: parseInt(AppStore.bagItemsTotalAmount().totalWithSale), currency: AppConstants.CURRENCY, items: this.state.items.map(item => formatProductForGA(item.product, item.size)), coupon: 'unset', checkout_step: 3 })
  }
  onShippingSubmit = () => {
    const p = this.form.current.shippingAddressForm.current.form.current.refs
    const customer = AppStore.getCurrentCustomer()
    const data = this.getFormData(customer, p, 'shipping')
    const newCustomer = { ...customer, shipping: data }
    this.setState({ shippingIsDone: true }, () => {
      Analytics.gEcom('checkout_progress', { value: parseInt(AppStore.bagItemsTotalAmount().totalWithSale), currency: AppConstants.CURRENCY, items: this.state.items.map(item => formatProductForGA(item.product, item.size)), coupon: 'unset', checkout_step: 1 })
      AppActions.setCustomer(newCustomer)
    })
  }
  onBillingSubmit = () => {
    const p = this.form.current.billingAddressForm.current.form.current.refs
    const customer = AppStore.getCurrentCustomer()
    const data = this.getFormData(customer, p, 'billing')
    const newCustomer = { ...customer, billing: data }
    Analytics.gEcom('add_payment_info', {})
    this.setState({ billingIsDone: true }, () => {
      Analytics.gEcom('checkout_progress', { value: parseInt(AppStore.bagItemsTotalAmount().totalWithSale), currency: AppConstants.CURRENCY, items: this.state.items.map(item => formatProductForGA(item.product, item.size)), coupon: 'unset', checkout_step: 2 })
      AppActions.setCustomer(newCustomer)
    })
  }
  onClickShippingHeader = () => {
    this.setState({
      action: AppConstants.SHIPPING,
      shippingIsDone: false
    })
  }
  onClickBillingHeader = () => {
    if (!this.state.shippingIsDone) return
    this.setState({
      action: AppConstants.BILLING,
      billingIsDone: false
    })
  }
  onClickPaymentHeader = () => {
    if (!this.state.shippingIsDone || !this.state.billingIsDone) return
    this.setState({
      action: AppConstants.PAY,
      paymentIsDone: false
    })
  }
  onBillingAsShippingSubmit = () => {
    const customer = AppStore.getCurrentCustomer()
    const newCustomer = { ...customer, billing: customer.shipping }
    this.setState({ billingIsDone: true }, () => {
      Analytics.gEcom('checkout_progress', { value: parseInt(AppStore.bagItemsTotalAmount().totalWithSale), currency: AppConstants.CURRENCY, items: this.state.items.map(item => formatProductForGA(item.product, item.size)), coupon: 'unset', checkout_step: 2 })
      AppActions.setCustomer(newCustomer)
    })
  }
  render() {
    const customer = AppStore.getCurrentCustomer()
    const isGuest = AppStore.getCustomerType() === AppConstants.GUEST_CUSTOMER ? true : false
    const areShoesValid = this.areShoesValid()
    return (
      <Layout>
        { areShoesValid &&
        <div className={`${styles.parent} u-mrg-b--40`}>
          <section className={`u-col-lg-10 u-offset-lg-1 u-row`}>
            <div className={`u-col-lg-7`}>
              <CheckingOutAs guest={isGuest} />
              <div className='u-height--40' />
              <CheckoutBillingShippingForm
                action={this.state.action}
                onShippingSubmit={this.onShippingSubmit}
                onBillingSubmit={this.onBillingSubmit}
                onBillingAsShippingSubmit={this.onBillingAsShippingSubmit}
                onClickShippingHeader={this.onClickShippingHeader}
                onClickBillingHeader={this.onClickBillingHeader}
                onClickPaymentHeader={this.onClickPaymentHeader}
                shippingIsDone={this.state.shippingIsDone}
                billingIsDone={this.state.billingIsDone}
                paymentIsDone={this.state.paymentIsDone}
                customer={customer}
                ref={this.form}
                />
              <div className='u-height--lg--60' />
            </div>
            <div className={`${styles.overviewPanel} u-col-lg-5`}>
              <OrderOverviewPanel
                shippingIsDone={this.state.shippingIsDone}
                billingIsDone={this.state.billingIsDone}
                paymentIsDone={this.state.paymentIsDone}
              />
              <div className='u-height--40' />
              <div className='u-height--only-lg--40' />
              <div className={`u-pad-x--lg`}>
                <ComplimentaryShipping />
              </div>
              <div className='u-height--only-lg--20' />
            </div>
          </section>
        </div>
        }
      </Layout>
    )
  }
}

export default Billing
