import React, { Component } from 'react'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import MainBtn from 'Components/UI/MainBtn'
import styles from './index.module.scss'
import Form from 'Components/UI/Form'
require('@gouch/to-title-case')

class CheckingOutAs extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {
      editMode: false
    }
  }
  componentDidMount() {
    if (this.props.guest) AppStore.on(AppConstants.SET_CUSTOMER, this.onSetCustomer)
  }
  componentWillUnmount() {
    if (this.props.guest) AppStore.off(AppConstants.SET_CUSTOMER, this.onSetCustomer)
  }
  onSetCustomer = () => {
    this.setState({
      editMode: false
    })
  }
  onEditEmail = () => {
    if (this.state.editMode) {
      if (this.form.current.validate()) {
        AppActions.setCustomer({
          email: this.form.current.refs.guestCustomerMail.state.value,
          type: AppConstants.GUEST_CUSTOMER
        })
      }
    } else {
      this.setState({
        editMode: true
      })
    }
  }
  render() {
    const customer = this.props.guest ? AppStore.getGuestCustomer() : AppStore.getCustomer()
    const buttonText = this.state.editMode ? `Save` : `Modify`
    const formData = [
      {
        type: 'input',
        props: {
          id: 'guestCustomerMail',
          className: 'u-col-lg-8',
          type: 'email',
          placeholder: customer.email,
          value: customer.email,
          required: true
        }
      }
    ]
    return (
      <div className={`u-col-lg-12`}>
        <div className={`${styles.sidePanel}`}>
          <div className={`${styles.sidePanelInside} t-bg-mediumgray`}>
            <h3 className={`${styles.sidePanelInsideTitle} t-color-black t-title-1 u-uppercase`}>You are {`${this.props.guest ? `checking out` : `logged in`}`} as:</h3>
            { this.props.guest &&
            <div>
              { !this.state.editMode && <p className={`t-color-gray t-paragraph-1 u-pad-t--20`}>{customer.email}</p>}
              { this.state.editMode && <Form className={`u-remove-autofill--gray`} items={formData} submit={this.onEditEmail} ref={this.form}/> }
              <MainBtn
                className={`${styles.editButton} t-title-1 u-uppercase u-absolute`}
                color={`black`}
                text={buttonText}
                onClick={this.onEditEmail}
              />
            </div>
            }
            { !this.props.guest &&
            <div>
              <p className={`t-color-gray t-paragraph-1 u-pad-t--20`}>{`${customer.title.length > 0 ? customer.title.toTitleCase()+' ' : ''}`}{customer.first_name.toLowerCase().toTitleCase()} {customer.last_name.toLowerCase().toTitleCase()}</p>
              <p className={`t-color-gray t-paragraph-1`}>{customer.email}</p>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

CheckingOutAs.defaultProps = {
  guest: true
}

export default CheckingOutAs
