import React, { Component } from 'react'
import styles from './index.module.scss'
import FormHeader from 'Components/UI/FormHeader'
import Checkbox from 'Components/UI/Checkbox'
import AddressForm from 'Components/UI/AddressForm'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import _CardForm from 'Components/UI/CardForm'
import Form from 'Components/UI/Form'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import animateScrollTo from 'animated-scroll-to'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements'

// const passwordForm = [
//   {
//     type: 'input',
//     props: {
//       id: 'registerGuestPassword',
//       className: 'u-col-12',
//       type: 'password',
//       placeholder: 'Password',
//       required: true
//     }
//   },
//   {
//     type: 'input',
//     props: {
//       id: 'registerConfirmGuestPassword',
//       className: 'u-col-12',
//       type: 'password',
//       placeholder: 'Confirm password',
//       required: true
//     }
//   }
// ]

class CheckoutBillingShippingForm extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.checkbox = React.createRef()
    this.createAccountCheckbox = React.createRef()
    this.billingAddressForm = React.createRef()
    this.shippingAddressForm = React.createRef()
    // this.guestPasswordForm = React.createRef()
    this.shippingSectionForm = React.createRef()
    this.billingSectionForm = React.createRef()
    this.paymentSectionForm = React.createRef()
    this.state = {
      isEditingBilling: true,
      isEditingShipping: true,
      createAccountForGuest: true,
      useBillingAsShipping: true
    }
  }
  componentDidUpdate() {
    setTimeout(this.arrangeSectionsHeight, 0)
  }
  scrollToSection = (el) => {
    clearTimeout(this.scrollTimeout)
    const speed = 800
    this.scrollTimeout = setTimeout(() => {
      const options = { speed }
      const scrollTopPos = el === 0 ? 0 : el.offsetTop
      animateScrollTo(scrollTopPos, options)
    }, speed)
  }
  arrangeSectionsHeight = () => {
    const shippingSectionForm = this.shippingSectionForm.current
    const billingSectionForm = this.billingSectionForm.current
    const paymentSectionForm = this.paymentSectionForm.current
    if (!shippingSectionForm || !billingSectionForm || !paymentSectionForm) return
    const shippingInnerSectionForm = shippingSectionForm.childNodes[0]
    const shippingSectionClientRect = shippingInnerSectionForm.getBoundingClientRect()
    const shippingSectionHeight = shippingSectionClientRect.height
    const billingInnerSectionForm = billingSectionForm.childNodes[0]
    const billingSectionHeight = billingInnerSectionForm.getBoundingClientRect().height
    const paymentInnerSectionForm = paymentSectionForm.childNodes[0]
    const paymentSectionHeight = paymentInnerSectionForm.getBoundingClientRect().height
    const headers = this.parent.current.getElementsByClassName('section-header')
    if (this.props.action === AppConstants.SHIPPING) {
      shippingSectionForm.style.height = `${shippingSectionHeight + 20}px`
      billingSectionForm.style.height = `${0}px`
      paymentSectionForm.style.height = `${0}px`
      this.scrollToSection(0)
    } else if (this.props.action === AppConstants.BILLING) {
      shippingSectionForm.style.height = `${0}px`
      billingSectionForm.style.height = `${billingSectionHeight + 40}px`
      paymentSectionForm.style.height = `${0}px`
      this.scrollToSection(headers[0])
    } else if (this.props.action === AppConstants.PAY) {
      shippingSectionForm.style.height = `${0}px`
      billingSectionForm.style.height = `${0}px`
      paymentSectionForm.style.height = `${paymentSectionHeight + 60}px`
      this.scrollToSection(headers[0])
    } else {
      shippingSectionForm.style.height = `${0}px`
      billingSectionForm.style.height = `${0}px`
      paymentSectionForm.style.height = `${0}px`
    }
  }
  onBillingAsShippingCheckboxChange = () => {
    this.setState({ useBillingAsShipping: this.state.useBillingAsShipping ? false : true })
  }
  onCheckboxCreateAccountForGuest = () => {
    this.setState({ createAccountForGuest: this.createAccountCheckbox.current.state.checked })
  }
  render() {
    const viewType = WindowStore.getViewType()
    const showEdit = viewType !== AppConstants.MOBILE
    const { isEditingBilling, isEditingShipping } = this.state
    const showEditShipping = this.props.shippingIsDone && this.props.action !== AppConstants.SHIPPING && showEdit
    const showEditBilling = this.props.billingIsDone && this.props.action !== AppConstants.BILLING && showEdit
    const showEditPayment = this.props.paymentIsDone && this.props.action !== AppConstants.PAY && showEdit
    const showSectionShipping = this.props.shippingIsDone && this.props.action !== AppConstants.SHIPPING
    const showSectionBilling = this.props.billingIsDone && this.props.action !== AppConstants.BILLING
    const showSectionPayment = this.props.paymentIsDone && this.props.action !== AppConstants.PAY
    const CardForm = injectStripe(_CardForm)
    const sectionNumber = (num, stateShippingDone, stateEdit) => (
      <div className={`${styles.sectionNumber} t-title-2 u-uppercase u-absolute`}>
        { stateShippingDone &&
        <svg className={`${styles.successIcon}`}>
          <use xlinkHref='#success-icon' />
        </svg>
        }
        { !stateEdit &&
        <div className={`${styles.number} u-fit`}>{num}</div>
        }
        <div className={`${styles.background} u-fit`}></div>
      </div>
    )
    return (
      <div ref={this.parent}>

        <div className={`section-header`}></div>

        <div className={`${this.props.action === AppConstants.SHIPPING ? styles.openSection : styles.closeSection}`}>
          <div onClick={this.props.onClickShippingHeader} className={`${styles.sectionHeader} u-btn`}>
            { sectionNumber(1, this.props.shippingIsDone, showSectionShipping) }
            <FormHeader className={`${styles.formHeader}`} title='Shipping Address' onClick={this.onEditShipping} preventToggle={!showEditShipping} editing={false} />
            <div className={`${styles.separator} u-col-12`}></div>
          </div>
          <div ref={this.shippingSectionForm} className={`${styles.sectionForm} u-pad-x--lg`}>
            <div>
              <AddressForm
                type={AppConstants.SHIPPING}
                ref={this.shippingAddressForm}
                submit={this.props.onShippingSubmit}
                onFail={this.arrangeSectionsHeight}
                isEditMode={isEditingShipping}
                data={this.props.customer.shipping}
              >
                <BackgroundBtn
                  className='u-col-lg-8 u-mrg-t--40'
                  text='Save and Continue to Billing'
                  type='submit'
                  fullBlack
                  highlighted
                />
              </AddressForm>
            </div>
          </div>
          <div className='u-height--40' />
        </div>


        <div className={`section-header`}></div>


        <div className={`${this.props.action === AppConstants.BILLING ? styles.openSection : styles.closeSection}`}>
          <div onClick={this.props.onClickBillingHeader} className={`${styles.sectionHeader} u-btn`}>
            { sectionNumber(2, this.props.billingIsDone, showSectionBilling) }
            <FormHeader className={`${styles.formHeader}`} title='billing address' onClick={this.onEditBilling} preventToggle={!showEditBilling} editing={false} />
            <div className={`${styles.separator} u-col-12`}></div>
          </div>
          <div ref={this.billingSectionForm} className={`${styles.sectionForm} u-pad-x--lg`}>
            <div>
              <Form submit={this.props.onBillingAsShippingSubmit} onFail={this.arrangeSectionsHeight}>
                <Checkbox
                  id='billingAsShipping'
                  className='u-fit-w u-mrg-t--40 u-pad-x--lg t-paragraph-0 t-color-black'
                  value={this.state.useBillingAsShipping}
                  onChange={this.onBillingAsShippingCheckboxChange}
                  label='My billing information is the same as my shipping information.'
                  ref={this.checkbox}
                  checked={this.state.useBillingAsShipping}
                />
                <p className={`u-pad-x--lg t-color-gray u-col-lg-12 t-paragraph-0 u-pad-t--10 u-mrg-b--20`}>If you want to use a different billing address, please unselect this box.</p>
                { this.state.useBillingAsShipping &&
                <BackgroundBtn
                  className='u-col-lg-8 u-mrg-t--40'
                  text='Save and Continue to payment'
                  type='submit'
                  fullBlack
                  highlighted
                />
                }
              </Form>
              { !this.state.useBillingAsShipping &&
              <AddressForm
                type={AppConstants.BILLING}
                ref={this.billingAddressForm}
                submit={this.props.onBillingSubmit}
                onFail={this.arrangeSectionsHeight}
                isEditMode={isEditingBilling}
                data={this.props.customer.billing}
              >
                <BackgroundBtn
                  className='u-col-lg-8 u-mrg-t--40'
                  text='Save and Continue to payment'
                  type='submit'
                  fullBlack
                  highlighted
                />
              </AddressForm>
              }
            </div>
          </div>
          <div className='u-height--40' />
        </div>

        <div className={`section-header`}></div>

        <div className={`${this.props.action === AppConstants.PAY ? styles.openSection : styles.closeSection}`}>
          <div onClick={this.props.onClickPaymentHeader} className={`${styles.sectionHeader} u-btn`}>
            { sectionNumber(3, this.props.paymentIsDone, showSectionPayment) }
            <FormHeader className={`${styles.formHeader}`} title='PAYMENT' onClick={this.onEditBilling} preventToggle={!showEditPayment} editing={false} />
            <div className={`${styles.separator} u-col-12`}></div>
          </div>
          <div ref={this.paymentSectionForm} className={`${styles.sectionForm} u-pad-x--lg`}>
            <div className={``}>
              <StripeProvider apiKey={AppConstants.STRIPE_PUBLIC_KEY}>
                <Elements>
                  <CardForm />
                </Elements>
              </StripeProvider>
              <div className='u-height--60' />
            </div>
          </div>
        </div>

        {/* <FormHeader className={`u-pad-x--lg`} title='billing address' onClick={this.onEditBilling} preventToggle={true} editing={isEditingBilling} />
        <AddressForm
          type={AppConstants.BILLING}
          ref={this.billingAddressForm}
          submit={()=>{}}
          isEditMode={isEditingBilling}
          data={this.props.customer.billing}
        >
        </AddressForm>
        <div className='u-height--60' />
        { !this.props.customer.id &&
        <div>
          <Checkbox
            id='createAccountForGuest'
            className='t-title-1'
            value=''
            onChange={this.onCheckboxCreateAccountForGuest}
            label='Create an account for future use'
            ref={this.createAccountCheckbox}
            checked={this.state.createAccountForGuest}
          />
          <div className='u-height--10' />
          { this.state.createAccountForGuest &&
          <div>
            <div className='u-height--20' />
            <Form items={passwordForm} submit={() => {}} ref={this.guestPasswordForm}></Form>
            <div className='u-height--60' />
          </div>
          }
        </div>
        }
        { checkbox }
        { !this.state.useBillingAsShipping &&
        <div>
          <div className='u-height--60' />
          <FormHeader className={`u-pad-x--lg`} title='Shipping Address' onClick={this.onEditShipping} preventToggle={true} editing={isEditingShipping} />
          <AddressForm
            type={AppConstants.SHIPPING}
            ref={this.shippingAddressForm}
            submit={()=>{}}
            isEditMode={isEditingShipping}
            data={this.props.customer.shipping}
          >
          </AddressForm>
          <div className='u-height--60' />
        </div>
        } */}
      </div>
    )
  }
}

export default CheckoutBillingShippingForm
