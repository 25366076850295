import React from 'react'
// import styles from './index.module.scss'

const FormHeader = (props) => {
  const editTxt = props.editTxt ? props.editTxt : 'edit'
  const closeTxt = props.closeTxt ? props.closeTxt : 'close'
  return (
    <div className={`${props.className}`}>
      <p className={`u-inline-block t-title-2 u-pad-b--20`}>{props.title}</p>
      { !props.preventToggle && <button className="t-title-1 u-uppercase u-float-r" onClick={props.onClick}>{ props.editing ? closeTxt : editTxt }</button>}
    </div>
  )
}

export default FormHeader
